import { createRouter, createWebHistory } from 'vue-router'
import homeView  from "@/views/index/homeView";
import layout from '../layout/bodyLayout.vue'
import auth from '../views/auth/authPage.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/index',
    component: layout,
    children: [
      {
        path: '/index',
        name: 'index',
        component: homeView
      }
    ]
  },
  {
    path: '/auth',
    name: 'auth',
    redirect: '/auth/login',
    component: auth,
    children: [
      {
        path: '/auth/login',
        name: 'login',
        component: () => import('@/views/auth/modules/loginForm.vue')
      },
      {
        path: '/auth/rest',
        name: 'rest',
        component: () => import('@/views/auth/modules/restForm.vue')
      },
      {
        path: '/auth/register',
        name: 'register',
        component: () => import('@/views/auth/modules/registerForm.vue')
      },
      {
        path: '/auth/enable',
        name: 'enable',
        component: () => import('@/views/auth/modules/enableForm.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
