import axios from 'axios';
import { clearToken, getToken } from '@/utils/auth';

axios.interceptors.request.use(
  (config) => {
    if (!config.responseType) {
      config.responseType = 'json';
    }
    config.headers.ver = '1.0.0';
    const token = getToken();
    if (token) {
      config.headers.token = token;
    }
    config.baseURL = 'https://node.wrhhh.com/';
    // config.baseURL = 'http://127.0.0.1:8360/';
    return config;
  },
  (error) => {
    // do something
    return Promise.reject(error);
  }
);
// add response interceptors
axios.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (response.config.responseType !== 'json') {
      return Promise.resolve(response);
    }
    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 0) {
      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if ([50103].includes(res.code)) {
        clearToken();
        window.location.href = '/';
      }
      return Promise.resolve(res);
    }
    return Promise.resolve(res);
  },
  (error) => {
    return Promise.reject(error.response);
  }
);
