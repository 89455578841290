<style lang="less" scoped>
.footer-wrap {
  background-color: #f2f2f2;
  padding: 0 8%;
  .footer-top{
    display: flex;
    justify-content: left;
    color: #000;
    padding: 40px 0;
    .footer-top-icp{
      display: flex;
      flex-direction: column;
      line-height: 2;
      color: #000;
      font-size: 12px;
      padding: 0 50px;
    }
    .footer-top-contact{
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0 50px;
      position: relative;
    }
    .footer-top-contact-logos{
      display: flex;
      flex: 1;
      .footer-top-contact-text{
        font-size: 16px;
      }
      .footer-top-contact-ways{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        a{
          width: 50px;
          height: 50px;
          cursor: pointer;
          box-sizing: border-box;
          border-radius: 50%;
          display: inline-block;
          transition: all .2s;
          transform: scale(.6);
          text-align: center;
          img{
            width: 50px;
            height: 50px;
          }
        }
        a:hover {
          transform: scale(1);
        }
      }
    }
  }
}

.footer-top .footer-top-contact:after {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 1px;
  background: #e5e5e5;
}
</style>

<template>
  <div class="footer-wrap">
    <div class="footer-top">
      <div class="footer-top-icp">
        <a target="_blank" href="https://beian.miit.gov.cn/">互联网ICP备案：陕ICP备20009957号-2</a>
        <span data-v-352cf2da="">Copyright © 2021-2026 王子君 版权所有</span>
      </div>
      <div class="footer-top-contact">
        <div class="footer-top-contact-logos">
          <img height="35px" src="../assets/logo_w.png">
        </div>
        <div class="footer-top-contact-logos" style="align-items: center;">
          <div class="footer-top-contact-text">
            遇到问题，需要帮助？
          </div>
          <div class="footer-top-contact-ways">
            <a href="mailto:1205251191@qq.com">
              <img src="../assets/email.png" width="30px" alt="">
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

</script>
