<style lang="less" scoped>
.index{
  .center-box{
    text-align: center;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 40px;
    .logoText{
      height: 300px;
      margin-right: 140px;
      margin-top: 100px;
    }
    .centerText{
      color: #333333;
      div{
        margin-top: 25px;
      }
      .bgtext{
        font-size: 30px;
        font-weight: bold;
        font-family: 宋体, SimSun, STSong, serif;
        position: relative;
        bottom: 4px;
        text-shadow: 2px 2px 9px rgba(0, 0, 0, 0.3);
      }
    }
  }
}
.news{
  display: flex;
  justify-content: center;
  padding: 70px 0;
}
.news-box{
  width: 300px;
  text-align: center;
  height: 480px;
  position: relative;
  will-change: transform;
  transition: 2s;
  img{
    width: 100%;
    height: 159px;
    margin-bottom: 15px;
    opacity: 0;
    transition: 2s;
  }
  .new-text{
    position: relative;
    bottom: 50px;
    transition: 2s;
    .more-button{
      opacity: 0;
      transition: 2s;
    }
    .text-title{
      font-size: 25px;
      font-weight: 600;
    }
    .text-title2{
      font-size: 20px;
      font-weight: 500;
    }
    .news-text{
      display: block;
      width: 250px;
      margin: 30px auto;
      font-size: 12px;
    }
  }
}
.news-box:hover{
  position: relative;
  background-color: #EAEAEA;
  opacity: 1;
  will-change: transform;
  transition: 2s;
  img{
    margin-bottom: 15px;
    opacity: 1;
    transition: 2s;
  }
  .new-text{
    transition: 2s;
    position: relative;
    bottom: 0;
    .more-button{
      opacity: 1;
      transition: 2s;
    }
  }
}
.more-button{
  display: block;
  width: 110px;
  font-size: 14px;
  padding: 3px 0;
  margin: 20px auto;
  border: 1px solid #000000;
  text-align: center;
}
</style>

<template>
  <div class="index">
    <div class="center-box">
      <img src="../../assets/logo_text.png" class="logoText" alt="">
      <div class="centerText">
        <div><span class="bgtext">恭</span>喜你！</div>
        <div>在互联网<span class="bgtext">万万千千</span>的网站中来到了这里，</div>
        <div>这里是<span class="bgtext">子君</span>，会将自己的一些经验分享到这里，</div>
        <div>这里以后会有很多<span class="bgtext">内容</span>，会有很多很多各种<span class="bgtext">乱七八糟</span>东西，</div>
        <div>例如，<span class="bgtext">开发</span>、<span class="bgtext">游</span>戏、<span class="bgtext">音</span>乐、随笔、好文分享……</div>
        <div>但是现在这里还是在<span class="bgtext">开发</span>阶段，</div>
        <div>有任何好的建议都可以。请联系我的<span class="bgtext">邮箱</span>，在这里<span class="bgtext">↓</span></div>
        <div>1205251191@<span class="bgtext">qq</span>.com</div>
      </div>
    </div>
    <div class="news clearfix">
      <template v-for="item in hotNews" :key="item.hotNewsId">
        <div class="news-box fl">
          <img :src="item.titleImg" alt="">
          <div class="new-text">
            <p class="text-title">{{item.hometime}}</p>
            <div style="width: 100%;height: 1px;background-color: #E8E8E8;z-index: -1;margin: 20px auto">
              <div style="width: 30px;height: 2px;background-color: #000000;margin: 0 auto"></div>
            </div>
            <p class="text-title2">{{item.title}}</p>
            <span class="news-text">{{item.homeText}}</span>
            <router-link to="/" :key="item.hotNewsId">
              <span class="more-button">更多</span>
            </router-link>
          </div>
        </div>
      </template>
    </div>
<!--    <div v-html="parsedMarkdown"></div>-->
  </div>
</template>
<script>
import {marked} from 'marked';

export default {
  data() {
    return {
      markdownString: '# Marked in Node.js\n\nRendered by **marked**.',
      parsedMarkdown: '',
      hotNews: [
        {
          hotNewsId: 1,
          title: "十年码农无人问 一朝删库天下知",
          hometime: " · 壹 · ",
          titleImg: "https://resource.jiupiao.vip/feedback/20230729/169062061270332.png",
          homeText: "这里主要讲的是如何优雅的使用Ctrl+C，Ctrl+V，Ctrl+X，以及如何优雅的删库跑路（开玩笑的）...",
        },
        {
          hotNewsId: 2,
          title: "我:哦~我懂了 脑子：你懂个屁！",
          hometime: " · 贰 · ",
          titleImg: "https://resource.jiupiao.vip/feedback/20230729/169061831508057.png",
          homeText: "这里主要讲的是入坑某个游戏后如何优雅的出坑，不会被一起玩的朋友骂的，我A了啊！(擦，你不会又要跑了吧？)",
        },
        {
          hotNewsId: 3,
          title: "我会弹吉他，但我不会弹吉他",
          hometime: " · 叁 · ",
          titleImg: "https://resource.jiupiao.vip/feedback/20230729/169061831508057.png",
          homeText: "这里主要讲的是一些音乐的推荐，比如喊麦啦，流行啦，电音啦，DJ啦，EMO啦，XX啦，(来跟我读，喊麦不是歌！)",
        },
        {
          hotNewsId: 4,
          title: "靠，你看不看，你看不看你！",
          hometime: " · 肆 · ",
          titleImg: "https://resource.jiupiao.vip/feedback/20230729/169061831508057.png",
          homeText: "这里主要讲，啊，不是，这里主要是一些莫名其妙的分享，我劝你最好看一下，你会的吧？(我藏得板砖往哪轮你懂我意思吧？)",
        },
      ]
    };
  },
  mounted() {
    this.parsedMarkdown = marked(this.markdownString);
  }
};
</script>
