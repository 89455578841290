<style lang="less" scoped>
.menu{
  position: relative;
  color: #fff;
  z-index: 100;
  background: #fff;
  .menu-list{
    height: 80px;
    padding: 0 130px;
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .menu-content{
      display: inline-flex;
      align-items: center;
    }
    .menu-item{
      display: inline-block;
      margin-left: 10px;
      padding: 0 15px;
      text-align: center;
      font-size: 18px;
      transition: background .5s;
      .menu-title{
        display: inline-block;
        line-height: 30px;
        text-align: center;
        font-size: 18px;
        color: #666;
        cursor: pointer;
        position: relative;
      }
      .menu-title::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: black;
        transform: scaleX(0);
        transform-origin: left;
        transition: transform 0.3s ease;
        pointer-events: none;
      }
      .menu-title:hover::after {
        transform: scaleX(1);
      }
      .menu-title:hover{
        color: #333!important;
      }
    }

    .menu-item:hover{
      color:#333;
      background:#fff
    }
    .user-icon{
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      display: flex;
      .menu-title-signin{
        font-size: 16px;
      }
      .menu-title{
        padding: 0 15px;
        margin-left: 15px;
        line-height: 40px;
        color: #666;
        display: flex;
        transition: all .2s;
      }
      .menu-title-black-block{
        padding: 0 30px;
        color: #fff;
        border-radius: 30px;
        background: #000;
      }
    }
  }
  .menu-icon{
    position: relative;
    z-index: 11;
    margin-right: 20px;
    cursor: pointer;
    span{
      position: relative;
      bottom: 10px;
      font-weight: bold;
      margin-left: 5px;
      font-size: 26px;
    }
  }
}
.white-background-color{
  color: #666;
  background-color: #fff;
  border-bottom: 1px solid #e5e5e5;
}
.user-login{
  display: inline-flex;
  align-items: center;
}
.icon{
  font-size: 20px;
  margin-right: 40px;
}
.bordershadow{
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
}
.demo-arrow {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  padding: 10px;
  width: 200px;
  background-color: var(--color-bg-popup);
  border-radius: 4px;
}
</style>

<template>
  <div class="menu white-background-color">
    <div class="menu-list">
      <div class="menu-content">
          <div class="menu-icon" @click="goPage('/')">
            <img src="../assets/logo_w.png" height="42" alt="">
          </div>
          <div class="menu-item" v-for="item in page" :key="item" @click="goPage(item.link)">
            <span class="menu-title">{{ item.title }}</span>
          </div>
      </div>
      <div class="user-icon" v-if="loginStatus === false">
        <span class="menu-title menu-title-signin" @click="goPage('/auth')">登录</span>
        <span class="menu-title menu-title-black-block" @click="goPage('/auth/register')">注册</span>
      </div>
      <div class="user-login" v-if="loginStatus === true">
        <a-tooltip content="工作台" position="bottom">
          <icon-apps class="icon" />
        </a-tooltip>
        <a-dropdown trigger="hover">
          <icon-notification class="icon" />
          <template #content>
            <a-doption>消息通知</a-doption>
            <a-doption>审核通知</a-doption>
            <a-doption>消息中心</a-doption>
          </template>
        </a-dropdown>
        <a-trigger class="bordershadow" trigger="hover" show-arrow>
          <img :src="userInfo.avatar" height="35" alt="">
          <template #content>
            <div class="demo-arrow">
              <a-empty />
            </div>
          </template>
        </a-trigger>
      </div>
    </div>
  </div>
</template>

<script setup>
import router from "@/router";
import { watch, onBeforeUnmount, ref } from "vue";
import {infoApi} from "@/api/auth";

const page = [
  {
    title: '技术民工',
    link: '/joinus',
  },
  {
    title: '宅家游戏',
    link: '/corporate',
  },
  {
    title: '音乐国度',
    link: '/corporate',
  },
  {
    title: '好文分享',
    link: '/corporate',
  },
  {
    title: '杂学',
    link: '/corporate',
  },
]
const userInfo = ref({
  avatar: '',
  username: '',
  email: '',
  phone: '',
  status: '',
});
const loginStatus = ref(false);
const info = async () => {
  await infoApi().then(res => {
    if(res.code === 200) {
      userInfo.value = res.data
      if(!userInfo.value.avatar){
        userInfo.value.avatar = 'https://cdn.wrhhh.com/web/169111924294971.png'
      }
      loginStatus.value = true
    } else {
      loginStatus.value = false
    }
  })
      .catch(err => {
        console.log(err)
      })
}
const unwatch = watch(
    () => router.currentRoute,
    () => {
      // 调用 info() 函数
      info()
    },
    { immediate: true }
)

onBeforeUnmount(() => {
  unwatch()
})
const goPage = (link) => {
  router.push(link);
}

</script>
