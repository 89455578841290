<style lang="less" scoped>
  .contant{
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    .left{
      width: 38%;
      min-width: 500px;
      .leftBody{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .logo{
          margin-top: 17px;
          margin-left: 30px;
          width: 135.2px;
          height: 42px;
        }
      }
      .center{
        text-align: center;
      }
      .btmBox{
        width: 100%;
        min-width: 500px;
        height: 230px;
        background-color: #f2f2f2;
        color: #000;
        text-align: center;
        line-height: 230px;
        font-size: 16px;
      }
    }
    .right{
      width: 62%;
      background: #fff;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
  .bgtext{
    font-size: 30px;
    font-weight: bold;
    font-family: 宋体, SimSun, STSong, serif;
    position: relative;
    bottom: 4px;
    text-shadow: 2px 2px 9px rgba(0, 0, 0, 0.3);
  }
  .red{
    color: rgb(247, 19, 5);
  }
</style>

<template>
  <div class="contant">
    <div class="left">
      <div class="leftBody">
        <img src="../../assets/logo_w.png" class="logo" height="42" alt="" @click="goPage('/')">
        <div class="center">
          <img src="../../assets/logo_text.png" height="360" alt="">
        </div>
        <div class="btmBox">
          <div>
            <span class="bgtext red">子君</span>の<span class="bgtext">轻分享</span>：一个<span class="bgtext red">优雅</span>的分享网站~
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <router-view />
    </div>
  </div>
</template>

<script setup>
import router from "@/router";

const goPage = (link) => {
  router.push(link)
}
</script>
