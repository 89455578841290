import axios from "axios";

// 登录
export function loginApi(data) {
  return axios.post('/user/auth/login', data);
}

// 重置密码验证码发送
export function sendResetCodeApi(data) {
  return axios.post('/user/auth/sendResetCode', data);
}

// 重置密码
export function resetPasswordApi(data) {
  return axios.post('/user/auth/resetPassword', data);
}

// 注册
export function registerApi(data) {
  return axios.post('/user/auth/register', data);
}

// 获取激活验证码
export function sendEmailCodeApi(data) {
  return axios.post('/user/auth/sendEmailCode', data);
}

// 获取激活验证码
export function enableApi(data) {
  return axios.post('/user/auth/enable', data);
}

// 获取激活验证码
export function infoApi(data) {
  return axios.post('/user/auth/info', data);
}
